import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';


function Redirect({ to }) {
  React.useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
}

function DynamicRedirect() {
  const { product_id } = useParams();
  const targetUrl = `https://menu.taac-app.com/trinca/uuid/${product_id}`;

  return <Redirect to={targetUrl} />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirect to="https://menu.taac-app.com" />} />
        <Route path="/ciz-secure" element={<Redirect to="https://menu.taac-app.com/ciz/8bcbfd23-fbc8-47a4-9361-cea13eba8af7" />} />
        <Route path="/ciz" element={<Redirect to="https://menu.taac-app.com/ciz" />} />
        <Route path="/trinca" element={<Redirect to="https://menu.taac-app.com/trinca" />} />
        <Route path="/trinca-mescita" element={<Redirect to="https://menu.taac-app.com/trinca" />} />
        <Route path="/trinca/:product_id" element={<DynamicRedirect />} />
        <Route path="/trinca-mescita/:product_id" element={<DynamicRedirect />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
